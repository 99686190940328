export const NBanalyticsConfig = {
  topProducts: [
    {
      imageURL:
        'https://media.crystallize.com/andisor-demo/24/2/26/32/ml860bb2_nb_02_idollarpdpflexf2dollarandqlt80andfmtwebpandwid440andhei440.webp',
      productURL:
        '/product/65dd1d2bbceda7b8aa51653e?variant=Raincloud%20with%20light%20arctic%20grey%20and%20nb%20navy',
      brandName: 'New Balance',
      productName: '860v2',
      rrp: 230,
      whs: 100.36,
    },
    {
      imageURL:
        'https://media.crystallize.com/andisor-demo/24/3/12/18/inriver-images_be2810c3-412f-4609-ba26-6a8232af4c67.jpg',
      productURL: '/product/65efb1b0a7a81b61d3222c4d?variant=Black',
      brandName: 'New Balance',
      productName: "MEN'S ESSENTIALS HOODIE",
      rrp: 79.99,
      whs: 36.36,
    },
    {
      imageURL:
        'https://media.crystallize.com/andisor-demo/24/3/12/5/inriver-images_4f3a3947-8a11-462b-adfa-53739a301e51.jpg',
      productURL: '/product/65efb19d1ccee6adfb8062bc?variant=Grey',
      brandName: 'New Balance',
      productName: "FF 680 V7 WOMEN'S RUNNING SHOES",
      rrp: 49.99,
      whs: 68.18,
    },
    {
      imageURL:
        'https://media.crystallize.com/andisor-demo/24/3/12/24/inriver-images_0b33d577-c6f3-4d85-9b45-d31424f85319.jpg',
      productURL: '/product/65efb1bda7a81b61d3222c4f?variant=Blue',
      brandName: 'New Balance',
      productName: "WOMEN'S IMPACT RUN TANK",
      rrp: 49.99,
      whs: 22.72,
    },
    {
      imageURL:
        'https://media.crystallize.com/andisor-demo/24/3/12/48/inriver-images_4af44482-bc25-4141-81f9-380458e811b5.jpg',
      productURL: '/product/65efb1dda7a81b61d3222c52?variant=Pink',
      brandName: 'New Balance',
      productName: "FRESH FOAM X 880V12 JUNIOR'S RUNNING SHOES (WIDTH M)",
      rrp: 129.99,
      whs: 59.09,
    },
    {
      imageURL:
        'https://media.crystallize.com/andisor-demo/24/3/12/37/inriver-images_aab5da76-ecb7-4f5a-8ddf-0ac111e46898.jpg',
      productURL: '/product/65efb1d11ccee6adfb8062be?variant=Green',
      brandName: 'New Balance',
      productName: "MEN'S ESSENTIALS LOGO TEE",
      rrp: 39.99,
      whs: 18.18,
    },
    {
      imageURL:
        'https://media.crystallize.com/andisor-demo/24/3/12/28/inriver-images_d092e0ab-2b2e-47da-b9b5-9d855b299c8e.jpg',
      productURL: '/product/65efb1bda7a81b61d3222c4f?variant=Red',
      brandName: 'New Balance',
      productName: "WOMEN'S IMPACT RUN TANK",
      rrp: 49.99,
      whs: 22.72,
    },
  ],
  slowMovers: [
    {
      imageURL:
        'https://media.crystallize.com/andisor-demo/24/2/26/35/u9060nrh_nb_02_idollarpdpflexf2dollarandqlt80andfmtwebpandwid440andhei440.webp',
      productURL:
        '/product/65dd1d2fe2ba75beec483b60?variant=Blue%20agate%20with%20black%20and%20rich%20oak',
      brandName: 'New Balance',
      productName: '9060',
      salesThisWeek: 7,
      stock: 416,
      sellThrough: '3.4',
    },
    {
      imageURL:
        'https://media.crystallize.com/andisor-demo/24/3/12/43/inriver-images_43cd9aa9-00c8-4ed2-9e3c-35d0db68ae7c.jpg',
      productURL: '/product/65efb1d11ccee6adfb8062be?variant=Blue',
      brandName: 'New Balance',
      productName: "MEN'S ESSENTIALS LOGO TEE",
      salesThisWeek: 30,
      stock: 483,
      sellThrough: '1.9',
    },
    {
      imageURL:
        'https://media.crystallize.com/andisor-demo/24/3/12/37/inriver-images_aab5da76-ecb7-4f5a-8ddf-0ac111e46898.jpg',
      productURL: '/product/65efb1d11ccee6adfb8062be?variant=Green',
      brandName: 'New Balance',
      productName: "MEN'S ESSENTIALS LOGO TEE",
      salesThisWeek: 4,
      stock: 348,
      sellThrough: '0.9',
    },
    {
      imageURL:
        'https://media.crystallize.com/andisor-demo/24/2/26/32/ml860bb2_nb_02_idollarpdpflexf2dollarandqlt80andfmtwebpandwid440andhei440.webp',
      productURL:
        '/product/65dd1d2bbceda7b8aa51653e?variant=Raincloud%20with%20light%20arctic%20grey%20and%20nb%20navy',
      brandName: 'New Balance',
      productName: '860v2',
      salesThisWeek: 31,
      stock: 355,
      sellThrough: '2.2',
    },
    {
      imageURL:
        'https://media.crystallize.com/andisor-demo/24/3/12/54/inriver-images_57c6b433-4685-41a2-9ad4-e472a57d72bf.jpg',
      productURL: '/product/65efb1e418121d28b36561aa?variant=Black',
      brandName: 'New Balance',
      productName: "WOMEN'S IMPACT RUN CROP LEGGINGS",
      salesThisWeek: 32,
      stock: 343,
      sellThrough: '1.6',
    },
    {
      imageURL:
        'https://media.crystallize.com/andisor-demo/24/3/12/28/inriver-images_d092e0ab-2b2e-47da-b9b5-9d855b299c8e.jpg',
      productURL: '/product/65efb1bda7a81b61d3222c4f?variant=Red',
      brandName: 'New Balance',
      productName: "WOMEN'S IMPACT RUN TANK",
      salesThisWeek: 13,
      stock: 497,
      sellThrough: '4.0',
    },
    {
      imageURL:
        'https://media.crystallize.com/andisor-demo/24/2/26/41/wrcella4_nb_02_idollarpdpflexf2dollarandqlt80andfmtwebpandwid440andhei440.webp',
      productURL:
        '/product/65dd1d38bceda7b8aa516542?variant=White%20with%20bleached%20lime%20glo%20and%20hot%20mango',
      brandName: 'New Balance',
      productName: 'FuelCell SuperComp Elite v4',
      salesThisWeek: 16,
      stock: 477,
      sellThrough: '5.0',
    },
  ],
};

export const NBcardConfig = {
  topWeeklyProducts: {
    headingText: 'Top Products this week',
    products: [
      {
        imageURL:
          'https://media.crystallize.com/andisor-demo/24/2/26/41/wrcella4_nb_02_idollarpdpflexf2dollarandqlt80andfmtwebpandwid440andhei440.webp',
        productURL:
          '/product/65dd1d38bceda7b8aa516542?variant=White%20with%20bleached%20lime%20glo%20and%20hot%20mango',
        brandName: 'New Balance',
        productName: 'FuelCell SuperComp Elite v4',
      },
      {
        imageURL:
          'https://media.crystallize.com/andisor-demo/24/2/26/38/uwrpdcon_nb_02_idollarpdpflexf2dollarandqlt80andfmtwebpandwid440andhei440.webp',
        productURL:
          '/product/65dd1d34bceda7b8aa516540?variant=Grey%20matter%20with%20turtledove%20and%20black',
        brandName: 'New Balance',
        productName: 'WRPD RUNNER\n',
      },
      {
        imageURL:
          'https://media.crystallize.com/andisor-demo/24/2/26/32/ml860bb2_nb_02_idollarpdpflexf2dollarandqlt80andfmtwebpandwid440andhei440.webp',
        productURL:
          '/product/65dd1d2bbceda7b8aa51653e?variant=Raincloud%20with%20light%20arctic%20grey%20and%20nb%20navy',
        brandName: 'New Balance',
        productName: '860v2',
      },
      {
        imageURL:
          'https://media.crystallize.com/andisor-demo/24/2/26/35/u9060nrh_nb_02_idollarpdpflexf2dollarandqlt80andfmtwebpandwid440andhei440.webp',
        productURL:
          '/product/65dd1d2fe2ba75beec483b60?variant=Blue%20agate%20with%20black%20and%20rich%20oak',
        brandName: 'New Balance',
        productName: '9060',
      },
    ],
  },
  newProducts: {
    headingText: 'New Products',
    products: [
      {
        imageURL:
          'https://media.crystallize.com/andisor-demo/24/3/12/18/inriver-images_be2810c3-412f-4609-ba26-6a8232af4c67.jpg',
        productURL: '/product/65efb1b0a7a81b61d3222c4d?variant=Black',
        brandName: 'New Balance',
        productName: 'HOODIE',
      },
      {
        imageURL:
          'https://media.crystallize.com/andisor-demo/24/3/12/37/inriver-images_aab5da76-ecb7-4f5a-8ddf-0ac111e46898.jpg',
        productURL: '/product/65efb1d11ccee6adfb8062be?variant=Green',
        brandName: 'New Balance',
        productName: 'LOGO TEE',
      },
      {
        imageURL:
          'https://media.crystallize.com/andisor-demo/24/3/12/28/inriver-images_d092e0ab-2b2e-47da-b9b5-9d855b299c8e.jpg',
        productURL: '/product/65efb1bda7a81b61d3222c4f?variant=Red',
        brandName: 'New Balance',
        productName: 'RUN TANK',
      },
      {
        imageURL:
          'https://media.crystallize.com/andisor-demo/24/3/12/55/inriver-images_06882b2e-cc73-4cf9-884c-30b46968191b.jpg',
        productURL: '/product/65efb1e418121d28b36561aa?variant=Black',
        brandName: 'New Balance',
        productName: 'RUN CROP LEGGINGS',
      },
    ],
  },
};

export const NBReorderingCardConfig = {
  topOutOfCoreSizeProducts: {
    headingText: 'Out-of-Core-Sizes Products',
    products: [
      {
        imageURL:
          'https://media.crystallize.com/andisor-demo/24/2/26/32/ml860bb2_nb_02_idollarpdpflexf2dollarandqlt80andfmtwebpandwid440andhei440.webp',
        productURL:
          '/product/65dd1d2bbceda7b8aa51653e?variant=Raincloud%20with%20light%20arctic%20grey%20and%20nb%20navy',
        brandName: 'New Balance',
        productName: '860v2',
      },
      {
        imageURL:
          'https://media.crystallize.com/andisor-demo/24/2/26/26/ws327mf_nb_02_idollarpdpflexf2dollarandqlt80andfmtwebpandwid440andhei440.webp',
        productURL: '/product/65dd1d26ed3974e126483509?variant=Linen%20with%20sea%20salt',
        brandName: 'New Balance',
        productName: '327',
      },
      {
        imageURL:
          'https://media.crystallize.com/andisor-demo/24/2/26/38/uwrpdcon_nb_02_idollarpdpflexf2dollarandqlt80andfmtwebpandwid440andhei440.webp',
        productURL:
          '/product/65dd1d34bceda7b8aa516540?variant=Grey%20matter%20with%20turtledove%20and%20black',
        brandName: 'New Balance',
        productName: 'WRPD RUNNER\n',
      },
      {
        imageURL:
          'https://media.crystallize.com/andisor-demo/24/2/26/35/u9060nrh_nb_02_idollarpdpflexf2dollarandqlt80andfmtwebpandwid440andhei440.webp',
        productURL:
          '/product/65dd1d2fe2ba75beec483b60?variant=Blue%20agate%20with%20black%20and%20rich%20oak',
        brandName: 'New Balance',
        productName: '9060',
      },
    ],
  },
  topReorderProducts: {
    headingText: 'Reorder Recommendations',
    products: [
      {
        imageURL:
          'https://media.crystallize.com/andisor-demo/24/3/12/1/inriver-images_580beac1-7a24-4d9a-90d2-6e4281f5976b.jpg',
        productURL: '/product/65efb190a7a81b61d3222c4b?variant=White',
        brandName: 'New Balance',
        productName: "624 V5 WOMEN'S SNEAKERS (WIDTH 2E)",
      },
      {
        imageURL:
          'https://media.crystallize.com/andisor-demo/24/2/26/29/ws327mb_nb_02_idollarpdpflexf2dollarandqlt80andfmtwebpandwid440andhei440.webp',
        productURL: '/product/65dd1d26ed3974e126483509?variant=Rosewood%20with%20sea%20salt',
        brandName: 'New Balance',
        productName: '327',
      },
      {
        imageURL:
          'https://media.crystallize.com/andisor-demo/24/2/26/32/ml860bb2_nb_02_idollarpdpflexf2dollarandqlt80andfmtwebpandwid440andhei440.webp',
        productURL:
          '/product/65dd1d2bbceda7b8aa51653e?variant=Raincloud%20with%20light%20arctic%20grey%20and%20nb%20navy',
        brandName: 'New Balance',
        productName: '860v2',
      },
      {
        imageURL:
          'https://media.crystallize.com/andisor-demo/24/2/26/38/uwrpdcon_nb_02_idollarpdpflexf2dollarandqlt80andfmtwebpandwid440andhei440.webp',
        productURL:
          '/product/65dd1d34bceda7b8aa516540?variant=Grey%20matter%20with%20turtledove%20and%20black',
        brandName: 'New Balance',
        productName: 'WRPD RUNNER\n',
      },
    ],
  },
};
