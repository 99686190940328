export const kidstuffAnalyticsConfig = {
  topBrands: [
    {
      name: 'Jellycat',
      url: '/brands/jellycat',
      logoURL: 'https://media.crystallize.com/andisor-demo/24/5/22/4/logofile.png',
    },
    {
      name: 'Bambini',
      url: '/brands/bambini',
      logoURL: 'https://media.crystallize.com/andisor-demo/24/5/22/11/logofile.png',
    },
    {
      name: 'Icando Create',
      url: '/brands/icando-create',
      logoURL: 'https://media.crystallize.com/andisor-demo/24/5/22/14/logofile.png',
    },
    {
      name: 'Animal All Sorts',
      url: '/brands/animal-all-sorts',
      logoURL: 'https://media.crystallize.com/andisor-demo/24/5/23/1/145.jpg',
    },
    {
      name: 'Bright Child',
      url: '/brands/bright-child',
      logoURL: 'https://media.crystallize.com/andisor-demo/24/5/23/4/147.jpg',
    },
    {
      name: 'Bello',
      url: '/brands/bello',
      logoURL: 'https://media.crystallize.com/andisor-demo/24/5/23/3/146.jpg',
    },
    {
      name: 'Free Play',
      url: '/brands/free-play',
      logoURL: 'https://media.crystallize.com/andisor-demo/24/5/23/5/148.jpg',
    },
    {
      name: 'Freya Lane',
      url: '/brands/freya-lane',
      logoURL: 'https://media.crystallize.com/andisor-demo/24/5/23/7/150.jpg',
    },
    {
      name: 'Lego',
      url: '/brands/lego',
      logoURL: 'https://media.crystallize.com/andisor-demo/24/5/23/8/152.jpg',
    },
    {
      name: 'Saint Germain',
      url: '/brands/saint-germaine',
      logoURL: 'https://media.crystallize.com/andisor-demo/24/5/23/9/151.jpg',
    },
  ],
  topProducts: [
    {
      productName: 'Bashful Red Dragon Original Medium',
      brandName: 'Jellycat',
      productURL: '/product/664d810157d2695d104209cb',
      imageURL: `https://media.crystallize.com/andisor-demo/24/5/22/77/bas3rdr_1192x1490.jpg`,
    },
    {
      productName: 'Amuseable Potted Bamboo',
      brandName: 'Jellycat',
      productURL: '/product/664d80f194dc7c01cebd8245',
      imageURL: `https://media.crystallize.com/andisor-demo/24/5/22/70/a2pb-03_1192x1490.jpg`,
    },
    {
      productName: 'Baby Olivia Doll - Assorted',
      brandName: 'Bambini',
      productURL: '/product/664d7ed994dc7c01cebd823b',
      imageURL: `https://media.crystallize.com/andisor-demo/24/5/22/19/bambini1-10_1192x1490.png`,
    },
    {
      productName: 'Hearts Romper',
      brandName: 'Bambini',
      productURL: '/product/664d7ef657d2695d104209bb',
      imageURL: `https://media.crystallize.com/andisor-demo/24/5/22/32/bambini2-01_1192x1490.png`,
    },
    {
      productName: 'Burger Maker Dough Playset',
      brandName: 'Icando Create',
      productURL: '/product/664d80792a0fdf72cb14495f',
      imageURL: `https://media.crystallize.com/andisor-demo/24/5/22/43/106324529_burger_knetset_35_002_1192x1490.jpg`,
    },
    {
      productName: 'Picnic Blanket Colouring Fun',
      brandName: 'Icando Create',
      productURL: '/product/664d80892a0fdf72cb144961',
      imageURL: `https://media.crystallize.com/andisor-demo/24/5/22/50/3_01215107-fadd-47d3-9444-262928ab19e7_1192x1490.jpg`,
    },
    {
      productName: 'Unicorn Playsand Set',
      brandName: 'Icando Create',
      productURL: '/product/664d808f57d2695d104209c4',
      imageURL: `https://media.crystallize.com/andisor-demo/24/5/22/55/106344622_af_spielsand_set_einhorn_ek3_001_1192x1490.jpg`,
    },
  ],
};

export const kidstuffDiscoverTabConfig = {
  topWeeklyProducts: {
    headingText: 'Top Products this week',
    products: [
      {
        productName: 'Bashful Red Dragon Original Medium',
        brandName: 'Jellycat',
        productURL: '/product/664d810157d2695d104209cb',
        imageURL: `https://media.crystallize.com/andisor-demo/24/5/22/77/bas3rdr_1192x1490.jpg`,
      },
      {
        productName: 'Amuseable Potted Bamboo',
        brandName: 'Jellycat',
        productURL: '/product/664d80f194dc7c01cebd8245',
        imageURL: `https://media.crystallize.com/andisor-demo/24/5/22/70/a2pb-03_1192x1490.jpg`,
      },
      {
        productName: 'Baby Olivia Doll - Assorted',
        brandName: 'Bambini',
        productURL: '/product/664d7ed994dc7c01cebd823b',
        imageURL: `https://media.crystallize.com/andisor-demo/24/5/22/19/bambini1-10_1192x1490.png`,
      },
      {
        productName: 'Hearts Romper',
        brandName: 'Bambini',
        productURL: '/product/664d7ef657d2695d104209bb',
        imageURL: `https://media.crystallize.com/andisor-demo/24/5/22/32/bambini2-01_1192x1490.png`,
      },
    ],
  },
  topBrands: {
    headingText: 'Hot brands in your portfolio',
    brands: [
      {
        name: 'Jellycat',
        url: '/brands/jellycat',
        logoURL: 'https://media.crystallize.com/andisor-demo/24/5/22/4/logofile.png',
      },
      {
        name: 'Bambini',
        url: '/brands/bambini',
        logoURL: 'https://media.crystallize.com/andisor-demo/24/5/22/11/logofile.png',
      },
      {
        name: 'Icando Create',
        url: '/brands/icando-create',
        logoURL: 'https://media.crystallize.com/andisor-demo/24/5/22/14/logofile.png',
      },
      {
        name: 'Animal All Sorts',
        url: '/brands/animal-all-sorts',
        logoURL: 'https://media.crystallize.com/andisor-demo/24/5/23/1/145.jpg',
      },
    ],
  },
};

export const kidstuffReorderTabConfig = {
  topOutOfCoreSizeProducts: {
    headingText: 'Out-of-Core-Sizes Products',
    products: [
      {
        productName: 'Amuseable Potted Bamboo',
        brandName: 'Jellycat',
        productURL: '/product/664d80f194dc7c01cebd8245',
        imageURL: `https://media.crystallize.com/andisor-demo/24/5/22/70/a2pb-03_1192x1490.jpg`,
      },
      {
        productName: 'Baby Olivia Doll - Assorted',
        brandName: 'Bambini',
        productURL: '/product/664d7ed994dc7c01cebd823b',
        imageURL: `https://media.crystallize.com/andisor-demo/24/5/22/19/bambini1-10_1192x1490.png`,
      },
      {
        productName: 'Hearts Romper',
        brandName: 'Bambini',
        productURL: '/product/664d7ef657d2695d104209bb',
        imageURL: `https://media.crystallize.com/andisor-demo/24/5/22/32/bambini2-01_1192x1490.png`,
      },
      {
        productName: 'Burger Maker Dough Playset',
        brandName: 'Icando Create',
        productURL: '/product/664d80792a0fdf72cb14495f',
        imageURL: `https://media.crystallize.com/andisor-demo/24/5/22/43/106324529_burger_knetset_35_002_1192x1490.jpg`,
      },
    ],
  },
  topReorderProducts: {
    headingText: 'Reorder Recommendations',
    products: [
      {
        productName: 'Hearts Romper',
        brandName: 'Bambini',
        productURL: '/product/664d7ef657d2695d104209bb',
        imageURL: `https://media.crystallize.com/andisor-demo/24/5/22/32/bambini2-01_1192x1490.png`,
      },
      {
        productName: 'Burger Maker Dough Playset',
        brandName: 'Icando Create',
        productURL: '/product/664d80792a0fdf72cb14495f',
        imageURL: `https://media.crystallize.com/andisor-demo/24/5/22/43/106324529_burger_knetset_35_002_1192x1490.jpg`,
      },
      {
        productName: 'Picnic Blanket Colouring Fun',
        brandName: 'Icando Create',
        productURL: '/product/664d80892a0fdf72cb144961',
        imageURL: `https://media.crystallize.com/andisor-demo/24/5/22/50/3_01215107-fadd-47d3-9444-262928ab19e7_1192x1490.jpg`,
      },
      {
        productName: 'Unicorn Playsand Set',
        brandName: 'Icando Create',
        productURL: '/product/664d808f57d2695d104209c4',
        imageURL: `https://media.crystallize.com/andisor-demo/24/5/22/55/106344622_af_spielsand_set_einhorn_ek3_001_1192x1490.jpg`,
      },
    ],
  },
};
