export const cityBeachAnalyticsConfig = {
  topBrands: [
    {
      name: 'Rip Curl',
      url: '/brands/rip-curl',
      logoURL: 'https://media.crystallize.com/andisor-demo/24/5/13/7/logo-rip.jpg',
      discount: '55%',
      moq: '$500',
    },
    {
      name: 'Adidas',
      url: '/brands/adidas',
      logoURL: 'https://media.crystallize.com/andisor-staging/23/8/3/1/adi-logo-square.png',
      discount: '50%',
      moq: '$500',
    },
    {
      name: 'Tommy Hilfiger',
      url: '/brands/tommy-hilfiger',
      logoURL: 'https://media.crystallize.com/andisor-staging/23/8/3/3/30.png',
      discount: '50%',
      moq: '$500',
    },
    {
      name: 'Nike',
      url: '/brands/nike',
      logoURL: 'https://media.crystallize.com/andisor-staging/23/8/3/14/46.png',
      discount: '50%',
      moq: '$200',
    },
    {
      name: 'Crocs',
      url: '/brands/crocs',
      logoURL: 'https://media.crystallize.com/andisor-demo/24/2/12/17/crocs.jpg',
      discount: '55%',
      moq: '$1000',
    },
    {
      name: 'New Balance',
      url: '/brands/new-balance',
      logoURL:
        'https://media.crystallize.com/andisor-demo/24/2/26/1/370174912_698791618948349_3858592335324774813_n.jpg',
      discount: '50%',
      moq: '$100',
    },
    {
      name: 'Converse',
      url: '/brands/converse',
      logoURL: 'https://media.crystallize.com/andisor-demo/24/2/12/13/converse.jpg',
      discount: '50%',
      moq: '$500',
    },
    {
      name: 'Reebok',
      url: '/brands/reebok',
      logoURL: 'https://media.crystallize.com/andisor-demo/24/2/12/14/reebok.jpg',
      discount: '50%',
      moq: '$1000',
    },
    {
      name: 'Quiksilver',
      url: '/brands/quiksilver',
      logoURL: 'https://media.crystallize.com/andisor-demo/24/5/13/100/scontent-logo.jpeg',
      discount: '50%',
      moq: '$500',
    },
    {
      name: 'Vans',
      url: '/brands/vans',
      logoURL: 'https://media.crystallize.com/andisor-demo/24/5/13/106/logo-vans.jpeg',
      discount: '50%',
      moq: '$500',
    },
  ],
  topProducts: [
    {
      productName: 'Journeys Rider Snap Back Cap',
      brandName: 'Rip Curl',
      productURL: '/product/6641e63f2ceca4c570fc4dc6',
      imageURL: `https://media.crystallize.com/andisor-demo/24/5/13/91/1bxmhe_0090_1_1.jpg`,
      rrp: 49.99,
      whs: 20.25,
    },
    {
      productName: 'Journeys Pant',
      brandName: 'Rip Curl',
      productURL: '/product/6641e60e60d1f60a6718d29b',
      imageURL: `https://media.crystallize.com/andisor-demo/24/5/13/23/012mpa_0090-1.jpg`,
      rrp: 149.99,
      whs: 60.75,
    },
    {
      productName: 'Anti-Series Journey Hood',
      brandName: 'Rip Curl',
      productURL: '/product/6641e5fa75012eb865c318b3',
      imageURL: `https://media.crystallize.com/andisor-demo/24/5/13/50/033mfl_0076_1.jpg`,
      rrp: 129.99,
      whs: 52.65,
    },
    {
      productName: 'VaporCool Journeys Trip Tee',
      brandName: 'Rip Curl',
      productURL: '/product/6641e6222ceca4c570fc4dc3',
      imageURL: `https://media.crystallize.com/andisor-demo/24/5/13/35/0afmte_0076_4.jpg`,
      rrp: 54.99,
      whs: 22.27,
    },
    {
      productName: 'WRPD RUNNER',
      brandName: 'New Balance',
      productURL: '/product/65dd1d34bceda7b8aa516540',
      imageURL: `https://media.crystallize.com/andisor-demo/24/2/26/38/uwrpdcon_nb_02_idollarpdpflexf2dollarandqlt80andfmtwebpandwid440andhei440.webp`,
      rrp: 250,
      whs: 113.64,
    },
    {
      productName: '327',
      brandName: 'New Balance',
      productURL: '/product/65dd1d26ed3974e126483509',
      imageURL: `https://media.crystallize.com/andisor-demo/24/2/26/26/ws327mf_nb_02_idollarpdpflexf2dollarandqlt80andfmtwebpandwid440andhei440.webp`,
      rrp: 160,
      whs: 72.73,
    },
    {
      productName: 'Tommy Hilfiger Icon Stack Crest Sweatshirt',
      brandName: 'Tommy Hilfiger',
      productURL: '/product/653eea75a1a21a49c2f4e48d',
      imageURL: `https://media.crystallize.com/andisor-demo/23/10/29/1900/jd_anz0046788_b.jpg`,
      rrp: 199,
      whs: 90.45,
    },
  ],
};

export const cityBeachDiscoverTabConfig = {
  topWeeklyProducts: {
    headingText: 'Top Products this week',
    products: [
      {
        productName: 'Journeys Rider Snap Back Cap',
        brandName: 'Rip Curl',
        productURL: '/product/6641e63f2ceca4c570fc4dc6',
        imageURL: `https://media.crystallize.com/andisor-demo/24/5/13/91/1bxmhe_0090_1_1.jpg`,
      },
      {
        productName: 'Journeys Pant',
        brandName: 'Rip Curl',
        productURL: '/product/6641e60e60d1f60a6718d29b',
        imageURL: `https://media.crystallize.com/andisor-demo/24/5/13/23/012mpa_0090-1.jpg`,
      },
      {
        productName: 'Anti-Series Journey Hood',
        brandName: 'Rip Curl',
        productURL: '/product/6641e5fa75012eb865c318b3',
        imageURL: `https://media.crystallize.com/andisor-demo/24/5/13/50/033mfl_0076_1.jpg`,
      },
      {
        productName: 'VaporCool Journeys Trip Tee',
        brandName: 'Rip Curl',
        productURL: '/product/6641e6222ceca4c570fc4dc3',
        imageURL: `https://media.crystallize.com/andisor-demo/24/5/13/35/0afmte_0076_4.jpg`,
      },
    ],
  },
  topBrands: {
    headingText: 'Hot brands in your portfolio',
    brands: [
      {
        name: 'Rip Curl',
        url: '/brands/rip-curl',
        logoURL: 'https://media.crystallize.com/andisor-demo/24/5/13/7/logo-rip.jpg',
      },
      {
        name: 'Nike',
        url: '/brands/nike',
        logoURL: 'https://media.crystallize.com/andisor-staging/23/8/3/14/46.png',
      },
      {
        name: 'Adidas',
        url: '/brands/adidas',
        logoURL: 'https://media.crystallize.com/andisor-staging/23/8/3/1/adi-logo-square.png',
      },
      {
        name: 'Quiksilver',
        url: '/brands/quiksilver',
        logoURL: 'https://media.crystallize.com/andisor-demo/24/5/13/100/scontent-logo.jpeg',
      },
    ],
  },
};

export const cityBeachReorderTabConfig = {
  topOutOfCoreSizeProducts: {
    headingText: 'Out-of-Core-Sizes Products',
    products: [
      {
        productName: 'Journeys Pant',
        brandName: 'Rip Curl',
        productURL: '/product/6641e60e60d1f60a6718d29b',
        imageURL: `https://media.crystallize.com/andisor-demo/24/5/13/23/012mpa_0090-1.jpg`,
      },
      {
        productName: 'Nike Legacy91 - Golf Hat',
        brandName: 'Nike',
        productURL: '/product/653e37b7035f4f0e1fb9ea66',
        imageURL: `https://media.crystallize.com/andisor-demo/23/10/29/1485/legacy91-golf-hat-n44p0r.jpg`,
      },
      {
        productName: "Nike Dri-FIT - Men's Golf Hoodie",
        brandName: 'Nike',
        productURL: '/product/653e39991b5028e484db94aa',
        imageURL: `https://media.crystallize.com/andisor-demo/23/10/29/1653/dri-fit-golf-hoodie-wft9tv.jpg`,
      },
      {
        productName: 'VaporCool Journeys Trip Tee',
        brandName: 'Rip Curl',
        productURL: '/product/6641e6222ceca4c570fc4dc3',
        imageURL: `https://media.crystallize.com/andisor-demo/24/5/13/35/0afmte_0076_4.jpg`,
      },
    ],
  },
  topReorderProducts: {
    headingText: 'Reorder Recommendations',
    products: [
      {
        productName: 'Journeys Rider Snap Back Cap',
        brandName: 'Rip Curl',
        productURL: '/product/6641e63f2ceca4c570fc4dc6',
        imageURL: `https://media.crystallize.com/andisor-demo/24/5/13/91/1bxmhe_0090_1_1.jpg`,
      },
      {
        productName: '327',
        brandName: 'New Balance',
        productURL: '/product/65dd1d26ed3974e126483509',
        imageURL: `https://media.crystallize.com/andisor-demo/24/2/26/26/ws327mf_nb_02_idollarpdpflexf2dollarandqlt80andfmtwebpandwid440andhei440.webp`,
      },
      {
        imageURL: `https://media.crystallize.com/andisor-demo/23/10/29/1342/air-jordan-12-low-golf-shoes-bnwnnt.jpg`,
        productURL:
          '/product/653e3665a1a21a49c2f4df4f?variant=White/White/Metallic%20Silver/Varsity%20Red',
        brandName: 'Nike',
        productName: 'Air Jordan 12 Low',
      },
      {
        productName: 'Tommy Hilfiger Jay Trainers',
        brandName: 'Tommy Hilfiger',
        productURL: '/product/653eeb331b5028e484db983a',
        imageURL: `https://media.crystallize.com/andisor-demo/23/10/29/1900/jd_fm0fm04047ybr_c_0010_b.jpg`,
      },
    ],
  },
};
